import React, { useEffect } from "react";
import {useContext} from 'react';
import {View, ViewContext} from "../../../../pages/Syllabus";
import Icon from '../../../../assets/icons/SyllabusIcon.svg';
import {Group} from "../../../../interfaces/AuthentificationInterface";
import Selector from "./Selector";
import {Button} from "react-bootstrap";
import { PromotionUtils } from "../../../../utils/PromotionUtils";
import { useAuth } from "react-oidc-context";
import { SyllabusService } from "../../../../services/SyllabusService";
import { SyllabusState } from "../../../../interfaces/SyllabusInterface";

function HeaderSyllabus() {
    const auth = useAuth();
    let [courses, setCourses] = React.useState<Map<string, number>>(new Map<string, number>());
    const { currentView, role, currentPromo, setCurrentView, setCurrentPromo, setSelectedSyllabusCourse } = useContext(ViewContext);

    const [currentPromotion] = React.useState(PromotionUtils.getCurrentPromotion());
    const [studentPromotion] = React.useState<number>(auth.user?.profile["promotion"] as number);

    useEffect(() => {
        if (role === Group.Student) {// Get courses for dropdown when user is student
            PromotionUtils.getPromotionCoursesNamesId(auth , studentPromotion).then((Courses) => {
                const promises: Promise<void>[] = [];
                Courses.forEach((courseId, courseName) => {
                    const promise = SyllabusService.GetLastSyllabusByCourseId(auth, courseId.toString(), studentPromotion.toString())
                        .then((Syllabus) => {
                            if (Syllabus.status !== SyllabusState.validated) {
                                Courses.delete(courseName);
                            }
                        }).catch(() => {
                            Courses.delete(courseName);
                        });

                    promises.push(promise);
                });

                Promise.all(promises).then(() => {
                    setCourses(Courses);
                    if (Courses.size > 0) {
                        setSelectedSyllabusCourse(Courses.values().next().value);
                    }
                });
            });
        }
    }, []);
    
    return (
        <div className="page-header d-flex align-items-center border-1 p-2 justify-content-between white-background rounded bg-light my-1">
            {(currentView === View.SyllabusCreateFormView ||
          currentView === View.SyllabusFillFormView ||
          currentView === View.SyllabusAskModificationFormView) && ( // Fill and Create form views
                <Button
                    className="btn btn-primary"
                    onClick={() => setCurrentView(View.SyllabusTableView)}
                >
            Retour
                </Button>
            )}
            {currentView === View.SyllabusTableView &&
          role === Group.MajorLeader && ( // Majorleader syllabus table view
                <>
                    <Button
                        className="btn btn-primary "
                        onClick={() => setCurrentView(View.SyllabusCreateFormView)}
                        style={{ marginRight: "10px" }}
                    >
                Nouveau Syllabus
                    </Button>
                    <Selector
                        options={
                            new Map<string, number>([
                                [(currentPromotion - 1).toString(), currentPromotion - 1],
                                [currentPromotion.toString(), currentPromotion],
                                [(currentPromotion + 1).toString(), currentPromotion + 1],
                            ])
                        }
                        defaultValue={currentPromo}
                        setAction={(selectedPromotion) => {
                            setCurrentPromo(selectedPromotion.toString());
                        }}
                    />
                </>
            )}
            {currentView === View.GeneralView &&
          (role === Group.MajorLeader || role === Group.Teacher) && ( // Majorleader and teacher general syllabus view
                <Button
                    className="btn btn-primary"
                    onClick={() => setCurrentView(View.SyllabusTableView)}
                >
              Retour
                </Button>
            )}
            {currentView === View.GeneralView &&
          role === Group.Student && // General student view
          (courses.size === 0 ? (
              <p className="position-absolute start-10 m-auto">
              Aucun syllabus disponible
              </p>
          ) : (
              <Selector
                  options={courses}
                  setAction={(courseId) => {
                      setSelectedSyllabusCourse(courseId);
                  }}
              />
          ))}
            <h1 className="position-absolute start-50 m-auto">Syllabus</h1>
            <img
                src={Icon}
                alt="Logo syllabus"
                className="ms-auto"
                style={{ width: "35px" }}
            />
        </div>
    );
}

export default HeaderSyllabus;

