import React from "react";

// options : Name / ID pair for selector options
// setAction : Function to call when the selector is changed
function Selector(props: {
  options: Map<string, number>;
  setAction: (arg0: number) => void;
  defaultValue?: string;
}) {
    function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
        props.setAction(Number(e.target.value));
    }

    return (
        <div style={{ marginRight: "10px" }}>
            <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleChange}
                defaultValue={props.defaultValue}
            >
                {Array.from(props.options).map(([name, id]) => {
                    return (
                        <option key={name} value={id}>
                            {name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

export default Selector;
