import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import axios from 'axios';
import { Button, Modal, Table } from "react-bootstrap";
import { PresenceState } from "./Presence";
import { toast } from "react-toastify";

interface StudentRecord {
    login: string,
    firstName: string,
    lastName: string,
    promotion: number
}

interface IAttendanceRecord {
    uuid: string;
    courseId: number;
    date: Date;
    promotion: number;
    courseName: string;
    presence: string[];
    sameClass: boolean;
}

interface PresenceProps {
    name: string,
    isPresent?: boolean
}

interface CloseAttendanceProps {
    promotion: number,
    redactor: string,
    studentList: string[]
}

export default function DetailsAttendanceModal(props: {
    modalType: string | null;
    setModalType: (modalType: string) => void;
    setPresence: (presence: PresenceState) => void;
    setIsCheckPresenceOpen: (isCheckPresenceOpen: boolean) => void;
    show: boolean;
    onHide: (() => void) | undefined;
}) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [attendanceList, setAttendanceList] = useState<PresenceProps[]>([]);
    const [students, setStudents] = useState<string[]>([]);
    const auth = useAuth();
    const [isSubmitButtonDisable, SetSubmitButtonIsDisabled] = useState(false);

    useEffect(() => {
        // Get the list of students
        async function getStudents() {
            try
            {
                const studentsResponse = await axios.get<StudentRecord[]>(`${process.env.REACT_APP_SCOLARITE_API_URL}/student/students?promotion=${auth.user?.profile.promotion}`, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`
                    }
                });
                setStudents(studentsResponse.data.map(student => student.login));
            } catch (error)
            {
                console.log(error);
                toast.error("Une erreur est survenue");
            }
        }

        getStudents();
    }, [auth.user?.access_token, auth.user?.profile.promotion]);

    useEffect(() => {
        if (students.length === 0 || props.modalType !== "Details") return;

        async function getAllAttendanceRecord() {
            axios.get<IAttendanceRecord>(`${process.env.REACT_APP_ABSENCE_API_URL}/attendance/get-all-attendance`, {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                const presenceData = students.map(student => ({ name: student, isPresent: response.data.presence.includes(student) }));
                presenceData.sort((a, b) => {
                    if (a.isPresent === b.isPresent) {
                        return a.name.localeCompare(b.name);
                    }
                    return a.isPresent ? 1 : -1;
                });
                setAttendanceList(presenceData);
            }).catch(error => {
                if (error.response.status == 404) {
                    if (error.response.data.err.errorText == "No attendance record found") {
                        props.setIsCheckPresenceOpen(false);
                        console.log("No attendance record found");
                        return;
                    }
                }
                console.error(error);
                toast.error("Une erreur est survenue");
            });
        }

        // Start the function one time then scan every 5 seconds
        getAllAttendanceRecord();
        const intervalID = setInterval(async () => {
            try {
                await getAllAttendanceRecord();
            } catch (error) {
                console.error(error);
                toast.error("Une erreur est survenue");
            }
        }, 5000);

        // Clear the interval when the component is unmounted. This return is mandatory not to have a memory leak
        // eslint-disable-next-line consistent-return
        return () => clearInterval(intervalID);
    }, [auth.user?.access_token, props.modalType, students]);

    // Function to handle the Validation button to redirect the user to the confirmation dialog
    const handleSubmit = (e: any) => {
        e.preventDefault();
        setShowConfirmDialog(true);
    };

    // Function to close the attendance
    function validateAttendance() {
        SetSubmitButtonIsDisabled(true);
        axios.post<CloseAttendanceProps>(`${process.env.REACT_APP_ABSENCE_API_URL}/attendance/close-attendance-record`, {
            promotion: auth.user?.profile.promotion,
            studentList: attendanceList.filter(student => !student.isPresent).map(student => student.name)
        }, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(_ => {
            props.setPresence({ attendance: false });
            props.setModalType("null");
            SetSubmitButtonIsDisabled(false);
            props.onHide?.();
            toast.success("La fiche de présence a été validée");
        }).catch(error => {
            console.error(error);
            toast.error("Une erreur est survenue");
        });
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-dialog-scrollable"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Détails de la fiche de présence
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="border rounded col-md-6 py-2 text-center">
                        <h4 className="">{students.length}</h4>
                        <div>{`Étudiant(e)s`}</div>
                    </div>
                    <div className="border rounded col-md-6 py-2 text-center">
                        <h4 className="">{attendanceList.filter(student => student.isPresent).length}</h4>
                        <div>{`Présent(e)s`}</div>
                    </div>
                </div>
                {showConfirmDialog === false ? (
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th scope="col" className="col-md-6 text-center">Login</th>
                                <th scope="col" className="col-md-6 text-center">Présence</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceList.map((student: PresenceProps, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="col-md-6 text-center">{student.name}</td>
                                        <td className="col-md-6 text-center">{student.isPresent ? "✅" : "❌"}</td>
                                    </tr>);
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <div className="text-center py-3">
                        <h3>Êtes-vous sûr de vouloir soumettre ce formulaire ?</h3>
                        <div className="text-muted">Vous ne pourrez plus modifier la fiche de présence.</div>
                        <div className="mt-3">
                            <Button disabled={isSubmitButtonDisable} variant="success" onClick={validateAttendance}>
                                {isSubmitButtonDisable ? (
                                    'Veuillez patentier...'
                                ) : 'Oui' }
                            </Button>
                            <Button onClick={() => setShowConfirmDialog(false)}>Non</Button>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleSubmit}>Valider la fiche de présence</Button>
            </Modal.Footer>
        </Modal>
    );
}
