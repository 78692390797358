import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from 'react-bootstrap/Table';
import { Absence, Status } from "../../../../interfaces/AbsenceInterface";
import Footer from "../../absence/Footer";
import { sortByDate, statusColor } from "../../../../utils";
import DetailsModal from "../../absence/AbsenceModal";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";

function AbsenceTableModal(props: {studentSelected: string}) {
    const [absences, setAbsences] = useState<Absence[]>([]);
    const [absenceModal, setAbsenceModal] = useState<Absence | null>(null);
    const auth = useAuth();

    function getCurrentPromotion() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const promotionStartDate = new Date(currentYear, 2, 1); // March 1st of the current year
    
        // Check if the current date is before the start of the promotion
        if (currentDate < promotionStartDate) {
            return currentYear; // Current promotion is the current year
        } 
        return currentYear + 1; // Current promotion is the next year
        
    }

    useEffect(() => {
        const currentPromotion = getCurrentPromotion();
        if(props.studentSelected === "") {
            axios.get<Absence[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/absences/promotions`, {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    promotion: currentPromotion,
                    status: `${Status.EN_ATTENTE}`
                }
            }).then(response => {
                response.data = response.data.filter((absence) => absence.status === Status.EN_ATTENTE);
                setAbsences(sortByDate(response.data, "courseDate"));
            }, error => {
                toast.error("Une erreur est survenue");
            });
        }
        else {
            axios.get<Absence[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/absences/students/${props.studentSelected}`, {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                setAbsences(sortByDate(response.data, "courseDate"));
            }, error => {
                toast.error("Une erreur est survenue");
            });
        }
    }, [auth.user, props.studentSelected, absenceModal]);

    // The Footer component is only displayed when a student is selected, because it's useless to have status filters when all absences are En_Attente.
    return (
        <>
            <Table borderless responsive className="rounded mt-2 overflow-hidden" striped={true}>
                <thead className="text-center">
                    <tr className="border-bottom">
                        <th className="fs-4">Login</th>
                        <th className="fs-4">Cours</th>
                        <th className="fs-4">Date</th>
                        <th className="fs-4">Temporalité</th>
                        <th className="fs-4">Statut</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="text-center align-middle">
                    {absences.map((absence, index) => (
                        <tr key={index}>
                            <td>{absence.studentLogin}</td>
                            <td>{absence.courseName}</td>
                            <td>{new Date(absence.courseDate).toLocaleDateString('FR-fr')}</td>
                            <td>{absence.declarationId ? 'a priori' : 'a posteriori'}</td>
                            <td className="align-items-center">
                                <div className={`d-block mx-auto w-50 p-2 rounded text-white ${statusColor(absence.status)}`}>
                                    {absence.status.toString()}
                                </div>
                            </td>
                            <td>
                                {absence.status !== Status.NON_JUSTIFIE && <Button variant="primary" onClick={() => setAbsenceModal(absence)}>Détails</Button>}
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>
            {props.studentSelected !== "" &&
                <Footer filteredAbsences={absences} />
            }
            <DetailsModal setAbsenceModal={setAbsenceModal} absenceModal={absenceModal}/>
        </>
    );
}

export default AbsenceTableModal;
