import React, {useEffect, useState} from 'react';
import { Image } from "react-bootstrap";
import {useContext} from 'react';
import { View, ViewContext } from "../../../../pages/Syllabus";
import {Group} from "../../../../interfaces/AuthentificationInterface";
import { Syllabus, SyllabusState } from '../../../../interfaces/SyllabusInterface';
import { SyllabusService } from '../../../../services/SyllabusService';
import { useAuth } from 'react-oidc-context';
import { Course } from '../../../../interfaces/CourseInterface';
import { CourseService } from '../../../../services/CourseService';
import {toast} from "react-toastify";

import edit from '../../../../assets/icons/edit.svg';
import eye from '../../../../assets/icons/eye.svg';
import checkmark from '../../../../assets/icons/checkmark.svg';
import clipboardArrow from '../../../../assets/icons/clipboardArrow.svg';
import download from '../../../../assets/icons/download.svg';
import ModifOrFillModal from "./ModifOrFillModal";

function GetTeacherButtons(props: {syllabus: Syllabus}) {
    const { setCurrentView, setSelectedSyllabusCourse } = useContext(ViewContext);

    const handleButtonClick = (view: View) => {
        setSelectedSyllabusCourse(Number(props.syllabus.courseId));
        setCurrentView(view);
    };

    return (
        <div className="d-flex flex-row justify-content-center align-items-center">
            {props.syllabus.status === SyllabusState.toEdit ? (
                <button className="btn btn-primary h-40px m-2" onClick={() => handleButtonClick(View.SyllabusFillFormView)}>
                    <Image src={edit} fluid alt="Remplir syllabus" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
                </button>
            ) : (
                <button className="btn btn-primary m-2" onClick={() => handleButtonClick(View.GeneralView)}>
                    <Image src={eye} fluid alt="Détails syllabus" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
                </button>
            )}
        </div>
    );
}

function GetMajorLeaderButtons(props: {syllabus: Syllabus, course: Course}) {
    const auth = useAuth();
    const { setCurrentView, setSelectedSyllabusCourse } = useContext(ViewContext);
    const [showModifOrFillModal, setModifOrFillModal ] = useState(false);

    const handleButtonClick = (view: View) => {
        setSelectedSyllabusCourse(Number(props.syllabus.courseId));
        setCurrentView(view);
    };
    const handleShowModifOrFillModal = () => {
        setModifOrFillModal(true);
    };

    const handleExportSyllabus = async () => {
        await SyllabusService.ExportSyllabus(auth, props.syllabus.courseId, props.syllabus.promotion, props.course.courseName);
    };

    return (
        <div className="d-flex flex-row justify-content-center align-items-center">
            {props.syllabus.status === SyllabusState.validated ? (
                <button className="btn btn-primary m-1" onClick={() => handleShowModifOrFillModal()}>
                    <Image src={clipboardArrow} fluid alt="Demander une notification" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
                </button>
            ) : props.syllabus.status === SyllabusState.toEdit ? (
                <button className="btn btn-primary m-1" onClick={() => handleButtonClick(View.SyllabusFillFormView)}>
                    <Image src={edit} fluid alt="Modifier syllabus" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
                </button>
            ) : (
                <>
                    <button className="btn btn-primary m-1" onClick={() => handleShowModifOrFillModal()}>
                        <Image src={clipboardArrow} fluid alt="Demander une notification" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
                    </button>
                    <button className="btn btn-primary m-1" onClick={() => SyllabusService.SetValidatedState(auth, props.syllabus.courseId, props.syllabus.promotion)
                        .then(() => {
                            toast.success('Syllabus validé avec succès');
                            handleButtonClick(View.SyllabusTableView);
                        })}>
                        <Image src={checkmark} fluid alt="Valider syllabus" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
                    </button>
                </>
            )}
            <button className="btn btn-primary m-1" onClick={() => handleButtonClick(View.GeneralView)}>
                <Image src={eye} fluid alt="Détails syllabus" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
            </button>
            <button className="btn btn-primary m-1" onClick={() => {
                handleExportSyllabus();
            }}>
                <Image src={download} fluid alt="Exporter syllabus" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
            </button>
            <ModifOrFillModal courseId={Number(props.syllabus.courseId)} show={showModifOrFillModal} onHide={() => setModifOrFillModal(false)}/>
        </div>
    );
}

function GetModificationButtons(props: {syllabus: Syllabus, course: Course}) {
    const { role } = useContext(ViewContext);

    if (role === Group.Teacher) {
        return GetTeacherButtons(props);
    } else if (role === Group.MajorLeader) {
        return GetMajorLeaderButtons(props);
    }

    return null;
}

function RecapSyllabus(props: {syllabus: Syllabus}) {
    const auth = useAuth();
    let [course, setCourse] = React.useState({} as Course);

    useEffect(() => {
        CourseService.GetCourseById(auth, Number(props.syllabus.courseId)).then((Course) => {
            setCourse(Course);
        });
    }, [props.syllabus.courseId]);

    return (
        <div className="d-flex flex-row justify-content-between align-items-center w-100 text-center my-2">
            <div className="card-group h-100 w-100">
                {[
                    { header: "Cours", text: course?.courseName ?? "Cours non nommé" },
                    { header: "Promotion", text: props.syllabus.promotion },
                    {
                        header: "Dernière modification",
                        text: props.syllabus.lastModificationDate,
                    },
                    { header: "Status", text: props.syllabus.status },
                    {
                        header: "Modifications",
                        text: (
                            <GetModificationButtons
                                syllabus={props.syllabus}
                                course={course}
                            />
                        ),
                    },
                ].map((item, index) => (
                    <div className="card" key={index}>
                        <h5 className="card-header">{item.header}</h5>
                        <div className="card-body">
                            {typeof item.text === "string" ? (
                                <p className="card-text">{item.text}</p>
                            ) : (
                                item.text
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RecapSyllabus;
