import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import AbsenceTable from '../components/FFO/absence/AbsenceTable';
import DeclarationTable from '../components/FFO/absence/DeclarationTable';
import AbsenceFooter from '../components/FFO/absence/Footer';
import AbsenceHeader from '../components/FFO/absence/Header';
import Selector from '../components/FFO/absence/Selector';
import AbsenceStudentTable from '../components/student/absence/AbsenceTable';
import DeclarationStudentTable from '../components/student/absence/DeclarationTable';
import { Absence, Declaration } from '../interfaces/AbsenceInterface';
import { Group } from '../interfaces/AuthentificationInterface';

export enum PageTab {
    Absence = "Absences",
    Declaration = "Déclarations"
}

const Absences : React.FC = () => {
    const [studentSelected, setStudentSelected] = useState<string>("");
    const [filteredTable, setFilteredTable] = useState<any[] | undefined | null>(null);
    const [tab, setTab] = useState(PageTab.Absence);
    const [promotion, setPromotion] = useState<string>('');
    const auth = useAuth();
    const userGroup = auth.user?.profile.group as Array<Group>;

    useEffect(() => {
        if (window.location.pathname === '/absences/declaration') {
            setTab(PageTab.Declaration);
        }
    }
    , []);

    if(userGroup.includes(Group.MajorLeader)){
        return (
            <>
                <AbsenceHeader setTab={setTab} tab={tab}/>
                <Selector setStudentSelected={setStudentSelected} tabs={tab} promotion={promotion} setPromotion={setPromotion}/>
                {tab === PageTab.Absence && <AbsenceTable studentSelected={studentSelected} filteredAbsences={filteredTable as Absence[]| undefined | null} setFilteredAbsences={setFilteredTable} promotion={promotion}/>}
                {tab === PageTab.Declaration && <DeclarationTable studentSelected={studentSelected} filtereddeclarations={filteredTable as Declaration[]| undefined | null} setFiltereddeclarations={setFilteredTable} promotion={promotion}/>}
                <AbsenceFooter filteredAbsences={filteredTable}/>
            </>
        );
    }
    // if it is a student
    return(<>
        <AbsenceHeader setTab={setTab} tab={tab}/>
        {tab === PageTab.Absence && <AbsenceStudentTable studentSelected={studentSelected} filteredAbsences={filteredTable as Absence[]| undefined | null} setFilteredAbsences={setFilteredTable}/>}
        {tab === PageTab.Declaration && <DeclarationStudentTable studentSelected={studentSelected} filtereddeclarations={filteredTable as Declaration[]| undefined | null} setFiltereddeclarations={setFilteredTable}/>}
        <AbsenceFooter filteredAbsences={filteredTable}/>
    </>);

};

export default Absences;
