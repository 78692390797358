import React from "react";
import Button from "react-bootstrap/Button";
import AddModuleModal from "./AddModuleModal";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";

function Selector(props: { setPromotion: (arg0: number) => void; setSemester: (arg0: number) => void; semester: number; promotion: number}) {

    const [addmodule, setAddModule] = React.useState<boolean>(false);
    const auth = useAuth(); 
    const currentPromotion = getCurrentPromotion();

    function handleChangePromotion(e: React.ChangeEvent<HTMLSelectElement>) {
        props.setPromotion(Number(e.target.value));
    }

    function getCurrentPromotion() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const promotionStartDate = new Date(currentYear, 2, 1); // March 1st of the current year
        // Check if the current date is before the start of the promotion
        if (currentDate < promotionStartDate) {
            return currentYear; // Current promotion is the current year
        } 
        return currentYear + 1; // Current promotion is the next year
        
    }

    function downloadReport() {
        axios.get(`${process.env.REACT_APP_NOTE_API_URL}/v1/grades/overviewFile?promotion=${props.promotion}`, {
            headers: {Authorization: `Bearer ${auth.user?.access_token}` },
            responseType: "blob"
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            const a = document.createElement('a');
            a.href = url;
            a.download = `Rapport_${props.promotion}.xlsx`;
            a.click();
            a.remove();
        }, error => {
            toast.error("Une erreur est survenue");
        });
    }

    return (
        <>
            <div className="d-flex align-items-center">
                <select className="form-select rounded selector-size w-25" onChange={handleChangePromotion} defaultValue={props.promotion}>
                    <option value={currentPromotion + 1}>{currentPromotion + 1}</option>
                    <option value={currentPromotion}>{currentPromotion}</option>
                    <option value={currentPromotion - 1}>{currentPromotion - 1}</option>
                </select>
                <Button className="rounded m-2 fw-bold"
                    variant={`${props.semester === 1 ? 'primary' : 'secondary'}`}
                    onClick={() => {
                        props.setSemester(1);
                    }}>S8</Button>
                <Button className="rounded m-2 fw-bold"
                    variant={`${props.semester === 2 ? 'primary' : 'secondary'}`}
                    onClick={() => {
                        props.setSemester(2);
                    }} >S9</Button>
                <Button className="rounded my-2 fw-bold ms-auto" onClick={() => downloadReport()}>Télécharger le rapport</Button>
                <Button className="rounded my-2 fw-bold ms-2" onClick={() => setAddModule(true)}>Ajouter un module</Button>
            </div>
            {addmodule && <AddModuleModal promotion={props.promotion} setAddModule={setAddModule} addModule={addmodule}/>}
        </>
    );
}

export default Selector;
